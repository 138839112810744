//import './Projects.css';

function Projects() {
  return (
    <>
    <main>
      <h1>projects</h1>
    </main>
    </>
  );
}



export default Projects;
